<template>
  <div class="drawer">
    <input
      id="admin-drawer"
      ref="drawerToggle"
      type="checkbox"
      class="drawer-toggle"
    >
    <div class="drawer-content flex flex-col">
      <!-- Navbar -->
      <div
        ref="navbar"
        class="navbar bg-base-200"
      >
        <div class="flex-none lg:hidden">
          <label
            for="admin-drawer"
            class="btn btn-square btn-ghost"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="inline-block w-6 h-6 stroke-current"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </label>
        </div>
        <div class="flex-1 px-2 mx-2">
          <router-link to="/admin/dashboard">
            <BrandComponent />
          </router-link>
        </div>
        <div class="flex-none hidden lg:block">
          <ul class="menu menu-horizontal">
            <li>
              <router-link to="/admin/dashboard">
                Dashboard
              </router-link>
            </li>
            <li>
              <router-link to="/admin/bots">
                Bots
              </router-link>
            </li>
            <li>
              <router-link to="/admin/backtests">
                Backtests
              </router-link>
            </li>
            <li>
              <router-link to="/admin/accounts">
                Accounts
              </router-link>
            </li>
            <li>
              <router-link to="/admin/settings">
                Settings
              </router-link>
            </li>
            <li>
              <router-link to="/dashboard">
                Client Site
              </router-link>
            </li>
            <li><a @click="handleLogout">Logout</a></li>
          </ul>
        </div>
      </div>

      <!-- Animated FAB for mobile -->
      <transition name="fade-slide">
        <label
          v-if="showFAB"
          for="admin-drawer"
          class="btn btn-primary btn-circle fixed right-4 bottom-4 lg:hidden z-50 opacity-90 hover:opacity-100 shadow-lg transition-opacity duration-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="inline-block w-6 h-6 stroke-current"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </label>
      </transition>
    </div>
    <!-- Sidebar / Drawer -->
    <div class="drawer-side">
      <label
        for="admin-drawer"
        class="drawer-overlay"
      />
      <div class="menu p-4 w-[300px] h-full bg-base-300 text-base-content">
        <div class="mb-4">
          <BrandComponent />
        </div>
        <ul class="space-y-2">
          <li>
            <router-link
              to="/admin/dashboard"
              class="flex items-center p-2 rounded-lg hover:bg-base-200"
              @click="closeDrawer"
            >
              <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
              </svg> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>
              Dashboard
            </router-link>
          </li>
          <li>
            <router-link
              to="/admin/bots"
              class="flex items-center p-2 rounded-lg hover:bg-base-200"
              @click="closeDrawer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                  clip-rule="evenodd"
                />
              </svg>
              Bots
            </router-link>
          </li>
          <li>
            <router-link
              to="/admin/backtests"
              class="flex items-center p-2 rounded-lg hover:bg-base-200"
              @click="closeDrawer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"
                />
              </svg>
              Backtests
            </router-link>
          </li>
          <!-- <li>
            <router-link
              to="/admin/reports"
              class="flex items-center p-2 rounded-lg hover:bg-base-200"
              @click="closeDrawer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm2 10a1 1 0 10-2 0v3a1 1 0 102 0v-3zm2-3a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm4-1a1 1 0 10-2 0v7a1 1 0 102 0V8z"
                  clip-rule="evenodd"
                />
              </svg>
              Reports
            </router-link>
          </li> -->
          <li>
            <router-link
              to="/admin/accounts"
              class="flex items-center p-2 rounded-lg hover:bg-base-200"
              @click="closeDrawer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
                />
              </svg>
              Accounts
            </router-link>
          </li>
          <li>
            <router-link
              to="/admin/settings"
              class="flex items-center p-2 rounded-lg hover:bg-base-200"
              @click="closeDrawer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clip-rule="evenodd"
                />
              </svg>
              Settings
            </router-link>
          </li>
          <li>
            <router-link
              to="/dashboard"
              class="flex items-center p-2 rounded-lg hover:bg-base-200"
              @click="closeDrawer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                />
              </svg>
              Client Site
            </router-link>
          </li>
        </ul>
        <div class="mt-auto pt-4 border-t border-base-200">
          <a
            class="flex items-center p-2 rounded-lg hover:bg-base-200 text-error"
            @click="handleLogout"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                clip-rule="evenodd"
              />
            </svg>
            Logout
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BrandComponent from "@/components/BrandComponent.vue";

export default {
  name: "AdminNavbarComponent",
  components: {
    BrandComponent,
  },
  data() {
    return {
      showFAB: false,
      lastScrollPosition: 0,
      navbarHeight: 0,
    };
  },
  mounted() {
    this.navbarHeight = this.$refs.navbar?.offsetHeight || 0;
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    async handleLogout() {
      try {
        await this.logout();
        this.closeDrawer();
        // The router navigation is now handled in the store action
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Show FAB when scrolled past navbar
      this.showFAB = currentScrollPosition > this.navbarHeight;
      this.lastScrollPosition = currentScrollPosition;
    },
    closeDrawer() {
      if (this.$refs.drawerToggle) {
        this.$refs.drawerToggle.checked = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

/* Updated styles */
.drawer-side {
  z-index: 999;
}

.drawer-overlay {
  z-index: 998;
}

.menu {
  z-index: 1000;
}

/* Add smooth transition for hover effects */
.menu a {
  transition: background-color 0.2s ease;
}
</style>

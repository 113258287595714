import axios from "axios";

export default {
  namespaced: true,

  state: {
    btcPrice: null,
    ethPrice: null,
    solPrice: null,
    xrpPrice: null,
    trumpPrice: null,
    lastFetchTime: 0,
    CACHE_DURATION: 15000,
    MAX_RETRIES: 3,
    RETRY_DELAY: 2000,
    pollInterval: null,
    isLoading: false,
    error: null,
    isInitialized: false,
  },

  getters: {
    currentBTCPrice: (state) => state.btcPrice,

    getPrice: (state) => (crypto) => {
      const priceMap = {
        btc: state.btcPrice,
        eth: state.ethPrice,
        sol: state.solPrice,
        xrp: state.xrpPrice,
        trump: state.trumpPrice,
      };
      return priceMap[crypto.toLowerCase()];
    },

    isPriceFresh: (state) =>
      Date.now() - state.lastFetchTime < state.CACHE_DURATION,

    hasError: (state) => state.error !== null,
    isLoading: (state) => state.isLoading,
  },

  mutations: {
    SET_PRICES(state, prices) {
      if (!prices) {
        console.error("Received null or undefined prices");
        return;
      }

      // Normalize the price data to handle both formats
      const normalizedPrices = {
        btc: prices.btc || prices["BTC/USDT"] || null,
        eth: prices.eth || prices["ETH/USDT"] || null,
        sol: prices.sol || prices["SOL/USDT"] || null,
        xrp: prices.xrp || prices["XRP/USDT"] || null,
        trump: prices.trump || prices["TRUMP/USDT"] || null,
      };

      state.btcPrice =
        typeof normalizedPrices.btc === "number" && !isNaN(normalizedPrices.btc)
          ? normalizedPrices.btc
          : null;
      state.ethPrice =
        typeof normalizedPrices.eth === "number" && !isNaN(normalizedPrices.eth)
          ? normalizedPrices.eth
          : null;
      state.solPrice =
        typeof normalizedPrices.sol === "number" && !isNaN(normalizedPrices.sol)
          ? normalizedPrices.sol
          : null;
      state.xrpPrice =
        typeof normalizedPrices.xrp === "number" && !isNaN(normalizedPrices.xrp)
          ? normalizedPrices.xrp
          : null;
      state.trumpPrice =
        typeof normalizedPrices.trump === "number" && !isNaN(normalizedPrices.trump)
          ? normalizedPrices.trump
          : null;

      state.lastFetchTime = Date.now();
      state.error = null;
    },
    SET_POLL_INTERVAL(state, interval) {
      state.pollInterval = interval;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_ERROR(state, error) {
      state.error = error;
      console.error("Price module error:", error);
    },
    SET_INITIALIZED(state, value) {
      state.isInitialized = value;
    },
    RESET_STATE(state) {
      state.btcPrice = null;
      state.ethPrice = null;
      state.solPrice = null;
      state.xrpPrice = null;
      state.trumpPrice = null;
      state.lastFetchTime = 0;
      state.error = null;
      state.isLoading = false;
      state.isInitialized = false;
      if (state.pollInterval) {
        clearInterval(state.pollInterval);
        state.pollInterval = null;
      }
    },
  },

  actions: {
    async initializePrice({ dispatch, commit, state }) {
      if (state.isInitialized) {
        return;
      }

      commit("RESET_STATE");

      try {
        await dispatch("fetchPrices");
        dispatch("startPolling");
        commit("SET_INITIALIZED", true);
      } catch (error) {
        console.error("Failed to initialize price module:", error);
        commit("SET_ERROR", "Failed to initialize price data");
      }
    },

    async fetchWithRetry({ dispatch, state }, attempt = 1) {
      try {
        const response = await axios.get("/api/market/price/");

        if (!response.data) {
          throw new Error("Empty response from API");
        }

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        const rawPrices = response.data;
        const prices = {};

        // Normalize price data format
        Object.entries(rawPrices).forEach(([key, value]) => {
          const normalizedKey = key.split("/")[0].toLowerCase();
          prices[normalizedKey] = value;
        });

        // Ensure we have at least one valid price
        const hasValidPrice = Object.values(prices).some(
          (value) => typeof value === "number" && !isNaN(value)
        );

        if (!hasValidPrice) {
          throw new Error("No valid prices found in response");
        }

        // Add null values for any missing currencies
        const expectedCurrencies = ["btc", "eth", "sol", "xrp", "trump"];
        expectedCurrencies.forEach((currency) => {
          if (!(currency in prices)) {
            prices[currency] = null;
          }
        });

        return prices;
      } catch (error) {
        console.error(`Attempt ${attempt} failed:`, error.message);

        if (attempt < state.MAX_RETRIES) {
          await new Promise((resolve) =>
            setTimeout(resolve, state.RETRY_DELAY)
          );
          return dispatch("fetchWithRetry", attempt + 1);
        }
        throw error;
      }
    },

    async fetchPrices({ commit, state, getters, dispatch }) {
      if (getters.isPriceFresh) {
        console.log("Using cached prices:", {
          btc: state.btcPrice,
          eth: state.ethPrice,
          sol: state.solPrice,
          xrp: state.xrpPrice,
          trump: state.trumpPrice,
        });
        return {
          btc: state.btcPrice,
          eth: state.ethPrice,
          sol: state.solPrice,
          xrp: state.xrpPrice,
          trump: state.trumpPrice,
        };
      }

      commit("SET_LOADING", true);
      commit("SET_ERROR", null);

      try {
        console.log("Fetching new prices from API...");
        const data = await dispatch("fetchWithRetry");
        console.log("Received data from API:", data);
        commit("SET_PRICES", data);
        return data;
      } catch (error) {
        console.error("Error fetching crypto prices:", error);
        commit("SET_ERROR", "Failed to fetch latest prices");
        return {
          btc: state.btcPrice,
          eth: state.ethPrice,
          sol: state.solPrice,
          xrp: state.xrpPrice,
          trump: state.trumpPrice,
        };
      } finally {
        commit("SET_LOADING", false);
      }
    },

    startPolling({ commit, dispatch, state }) {
      if (state.pollInterval) {
        clearInterval(state.pollInterval);
        commit("SET_POLL_INTERVAL", null);
      }

      dispatch("fetchPrices");

      const interval = setInterval(async () => {
        if (!state.isLoading) {
          await dispatch("fetchPrices");
        }
      }, state.CACHE_DURATION);

      commit("SET_POLL_INTERVAL", interval);
    },

    cleanup({ commit }) {
      commit("RESET_STATE");
    },
  },
};

<template>
  <div class="modal modal-open">
    <div :class="['modal-box relative z-20', modalClass || 'max-w-md']">
      <!-- Close button -->
      <button
        v-if="showCloseButton"
        class="btn btn-sm btn-circle absolute right-2 top-2"
        @click="handleClose"
      >
        ✕
      </button>

      <!-- Title -->
      <h3 class="font-bold text-left text-xl mb-4">
        <slot name="title" />
      </h3>

      <!-- Main content -->
      <slot />

      <!-- Error message -->
      <div v-if="error" class="mt-4 text-error text-center">
        {{ error }}
      </div>
    </div>
    <div class="modal-backdrop" @click="handleClose" />
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted } from "vue";

export default defineComponent({
  name: "BaseModal",
  props: {
    error: {
      type: String,
      default: null,
    },
    modalClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };

    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    onMounted(() => {
      document.addEventListener("keydown", handleEscKey);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", handleEscKey);
    });

    return {
      handleClose,
    };
  },
});
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
</style>
